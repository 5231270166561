import { Container, Row, Col } from "reactstrap";
// import products from "../../assets/fake-data/products";
import ProductCard from "../../components/UI/product-card/ProductCard";
import Loader from "../../components/Loader";
import $ from "jquery";

import ReactPaginate from "react-paginate";

import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
// const searchedProduct = products;

function AllFood() {
  const [processLoader, setProcessLoader] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const [searchedProduct, setProducts] = useState([]);

  const productPerPage = 4;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = searchedProduct.slice(
    visitedPage,
    visitedPage + productPerPage
  );

  const pageCount = Math.ceil(searchedProduct.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  function deleteFoodSubmit(event, id) {
    event.preventDefault();
    if (!window.confirm("Are sure you want to delete this food?")) return;
    const loginToken = localStorage.getItem("token");
    let formData = new FormData();
    formData.append("id", id);
    $.ajax({
      url: "https://test.globalgoservices.com/delete-food",
      method: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      beforeSend: function (req) {
        req.setRequestHeader("Authorization", "Bearer " + loginToken);
        setProcessLoader(true);
      },
      success: function (response) {
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          fetchFoods();
          alert(response.message);
        }
      },
    });
  }

  useEffect(() => {
    fetchFoods();
  }, []);

  function fetchFoods() {
    const loginToken = localStorage.getItem("token");
    $.ajax({
      url: "https://test.globalgoservices.com/admin-page-data",
      method: "GET",
      processData: false,
      dataType: "json",
      contentType: false,
      beforeSend: function (req) {
        req.setRequestHeader("Authorization", "Bearer " + loginToken);
        setProcessLoader(true);
      },
      success: function (response) {
        console.log(response);
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          setProducts(response.message.products);
          // setData({
          //     totalProduct : response.message.totalProduct,
          //     totalOrder : response.message.totalOrder,
          // });
        }
      },
    });
  }

  return (
    <Container>
      {processLoader && <Loader />}

      <h4>Uploaded Food</h4>
      <Row>
        {displayPage.map((item) => (
          <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mb-4 mt-4">
            <div className="product__item d-flex flex-column justify-content-between">
              <div className="product__content">
                <img
                  className="product__img w-50"
                  src={
                    "https://test.globalgoservices.com/assets/img/" + item.image
                  }
                  alt="Pizza"
                />
                <h5>
                  <Link to={`/pizzas/${item.id}`}>{item.name}</Link>
                </h5>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-between">
                <span className="product__price mb-2">{item.price} NGN </span>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <Link
                    to={"/admin/edit-food/" + item.id}
                    className="btn btn-primary"
                  >
                    Edit
                  </Link>
                  <button
                    className="btn btn-danger"
                    onClick={(event) => deleteFoodSubmit(event, item.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Col>
        ))}
        <div className="d-flex justify-content-center mt-4 mb-4">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={changePage}
            previousLabel={"Prev"}
            nextLabel={"Next"}
            containerClassName="paginationBttns"
          />
        </div>
      </Row>
    </Container>
  );
}

export default AllFood;
