import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "reactstrap";
import Loader from "../components/Loader";
import $ from "jquery";

import products from "../assets/fake-data/products";
import ProductCard from "../components/UI/product-card/ProductCard";
import Helmet from "../components/Helmet/Helmet";
import ReactPaginate from "react-paginate";
import "../styles/pagination.css";

const Pizzas = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [processLoader, setProcessLoader] = useState(true);

  const [searchedProduct, setSearchedProduct] = useState([]);

  const productPerPage = 4;
  const visitedPage = pageNumber * productPerPage;
  const displayPage = searchedProduct.slice(
    visitedPage,
    visitedPage + productPerPage
  );

  const pageCount = Math.ceil(searchedProduct.length / productPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    $.ajax({
      url: "https://test.globalgoservices.com/all-foods",
      method: "GET",
      processData: false,
      dataType: "json",
      contentType: false,
      success: function (response) {
        console.log(response);
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          setSearchedProduct(response.message);
        }
      },
    });
  }, []);

  return (
    <Helmet title="All Food">
      {processLoader && <Loader />}

      <Container>
        <Row>
          {displayPage.map((item) => (
            <Col
              lg="3"
              md="4"
              sm="6"
              xs="6"
              key={item.id}
              className="mb-4 mt-4"
            >
              <ProductCard item={item} />
            </Col>
          ))}
          <div className="d-flex justify-content-center mt-4 mb-4">
            <ReactPaginate
              pageCount={pageCount}
              onPageChange={changePage}
              previousLabel={"Prev"}
              nextLabel={"Next"}
              containerClassName="paginationBttns"
            />
          </div>
        </Row>
      </Container>
    </Helmet>
  );
};

export default Pizzas;
