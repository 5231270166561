import { useState } from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col } from "reactstrap";
import Loader from "../../components/Loader";
import $ from "jquery";

function AdminLogin() {
  const [processLoader, setProcessLoader] = useState(false);
  const navigate = useNavigate();
  function processLogin(event) {
    event.preventDefault();
    let formData = new FormData(event.target);
    formData.append("login", "true");
    $.ajax({
      url: "https://test.globalgoservices.com/admin-login",
      method: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      beforeSend: function () {
        setProcessLoader(true);
      },
      success: function (response) {
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          navigate("/admin");
          localStorage.setItem("token", response.loginToken);
          alert(response.message);
        }
      },
    });
  }

  return (
    <Container>
      {processLoader && <Loader />}
      <Row>
        <Col md="8" className="m-auto mt-5 pb-">
          <h1>Login Admin</h1>
          <form
            className="card card-body mt-4"
            onSubmit={(event) => processLogin(event)}
          >
            <div className="mb-3">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                name="username"
                placeholder="Username"
              />
            </div>
            <div className="mb-3">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
              />
            </div>
            <div className="mb-3">
              <button className="btn btn-primary w-100">Submit</button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminLogin;
