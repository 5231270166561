import { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import $ from "jquery";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";

function Dashboard() {
  const [processLoader, setProcessLoader] = useState(false);
  const navigate = useNavigate();
  const [datas, setData] = useState({
    totalOrder: 0,
    totalProduct: 0,
  });

  useEffect(() => {
    const loginToken = localStorage.getItem("token");
    $.ajax({
      url: "https://test.globalgoservices.com/admin-page-data",
      method: "GET",
      processData: false,
      dataType: "json",
      contentType: false,
      beforeSend: function (req) {
        req.setRequestHeader("Authorization", "Bearer " + loginToken);
        setProcessLoader(true);
      },
      success: function (response) {
        console.log(response);
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          setData({
            totalProduct: response.message.totalProduct,
            totalOrder: response.message.totalOrder,
          });
        }
      },
    });
  }, []);

  return (
    <Container>
      {processLoader && <Loader />}
      <h1 className="mb-3">Welcome Admin</h1>
      <Row>
        <Col md="6">
          <div className="card">
            <div className="card-body">
              <h6>Total Uploaded Food</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h4>{datas.totalProduct}</h4>
                <a href="" className="btn btn-primary btn-sm">
                  view
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="card">
            <div className="card-body">
              <h6>Total Order Food</h6>
              <div className="d-flex justify-content-between align-items-center">
                <h4>{datas.totalOrder}</h4>
                <a href="" className="btn btn-primary btn-sm">
                  view
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
