import React from "react";

import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import "../styles/cart-page.css";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router";

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const [processLoader, setProcessLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clearCart = () => {
    dispatch(cartActions.clearItem());
  };

  function processLogin(event) {
    event.preventDefault();

    setProcessLoader(true);
    let formData = new FormData(event.target);
    formData.append("submit", "true");
    $.ajax({
      url: "https://test.globalgoservices.com/product-checkout",
      method: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          clearCart();
          navigate("/checkout");
          alert(response.message);
        }
      },
    });
  }

  return (
    <Helmet title="Cart">
      {processLoader && <Loader />}
      <CommonSection title="Your Cart" />
      <section>
        <Container>
          <Row>
            <Col lg="12">
              <form onSubmit={(event) => processLogin(event)}>
                {cartItems.length === 0 ? (
                  <h5 className="text-center">Your cart is empty</h5>
                ) : (
                  <>
                    <h5 className="mb-5">Summary of your order</h5>
                    <table className="table table-borderless mb-5 align-middle">
                      <tbody>
                        {cartItems.map((item, index) => (
                          <Tr item={item} key={item.id} i_ndex={index} />
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
                <div>
                  <div className="mb-3">
                    <label>FullName</label>
                    <input
                      type="text"
                      name="fullname"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <h6>
                    Subtotal: NGN
                    <span className="cart__subtotal">{totalAmount}</span>
                  </h6>
                  <p>Taxes and shipping will calculate at checkout</p>
                  <div className="cart__page-btn">
                    <div className="addTOCart__btn me-4">
                      <Link to="/pizzas">Continue Shopping</Link>
                    </div>
                    <button className="addTOCart__btn">
                      Proceed to checkout
                    </button>
                  </div>
                  <div className="mt-3"></div>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const Tr = (props) => {
  const { i_ndex, id, image, name, price, quantity } = props.item;
  console.log(i_ndex);
  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id));
  };
  return (
    <>
      <tr>
        <td className="text-center cart__img-box">
          <input hidden name={`carts[${id}][product_id]`} value={id} />
          <input hidden name={`carts[${id}][quantity]`} value={quantity} />
          <img src={"https://test.globalgoservices.com/assets/img/" + image} alt="" />
        </td>
        <td className="text-center">{name}</td>
        <td className="text-center">NGN {price}</td>
        <td className="text-center">{quantity}</td>
        <td className="text-center cart__item-del">
          <i className="ri-delete-bin-line" onClick={deleteItem}></i>
        </td>
      </tr>
    </>
  );
};

export default Cart;
