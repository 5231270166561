import AdminHeader from "../Header/AdminHeader";
import { Outlet } from "react-router-dom";


function AdminLayout() {
    return (
        <>
            <AdminHeader />
            <div className="mt-5">
                <Outlet />
            </div>
        </>
    )
}

export default AdminLayout;