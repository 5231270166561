import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";

import Loader from "../../components/Loader";
import $ from "jquery";

function EditFood(props) {
  const [processLoader, setProcessLoader] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const routeParams = useParams();

  useEffect(() => {
    $.ajax({
      url: "https://test.globalgoservices.com/get-food?id=" + routeParams.id,
      method: "GET",
      processData: false,
      dataType: "json",
      contentType: false,
      success: function (response) {
        console.log(response);
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          setData(response.message);
        }
      },
    });
  }, []);

  function editFoodSubmit(event) {
    event.preventDefault();
    const loginToken = localStorage.getItem("token");
    let formData = new FormData(event.target);
    formData.append("id", routeParams.id);
    formData.append("login", "true");
    $.ajax({
      url: "https://test.globalgoservices.com/edit-foods",
      method: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      beforeSend: function (req) {
        req.setRequestHeader("Authorization", "Bearer " + loginToken);
        setProcessLoader(true);
      },
      success: function (response) {
        setProcessLoader(false);
        if (response.status == "error") {
          alert(response.message);
        } else {
          navigate("/admin/all-food");
          alert(response.message);
        }
      },
    });
  }

  return (
    <Container>
      {processLoader && <Loader />}
      <Row>
        <Col md="8" className="m-auto">
          <div className="card">
            <div className="card-body">
              <h4>Upload Food</h4>
              <form onSubmit={(event) => editFoodSubmit(event)}>
                <div className="mb-3">
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    className="form-control"
                    defaultValue={data.name}
                  />
                </div>
                <div className="mb-3">
                  <label>Image</label>
                  <input type="file" name="image" className="form-control" />
                </div>

                <div className="mb-3">
                  <label>Price</label>
                  <input
                    type="number"
                    name="price"
                    className="form-control"
                    required
                    defaultValue={data.price}
                  />
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <textarea
                    className="form-control"
                    name="description"
                    cols={10}
                    required
                    defaultValue={data.description}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary w-100">Upload</button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EditFood;
