import { Routes, Route, Navigate } from "react-router-dom";
import AdminLogin from "../pages/Admin/AdminLogin";
import AdminLayout from "../components/Layout/AdminLayout";
import Dashboard from "../pages/Admin/Dashboard";
import UploadFood from "../pages/Admin/UploadFood";
import AllFood from "../pages/Admin/AllFoods";
import AllOrders from "../pages/Admin/AllOrders";
import EditFood from "../pages/Admin/EditFood";

function Admin() {
    return (
        <Routes>
            <Route path="/admin" element={<AdminLayout />}>
                <Route path="" element={<Dashboard />} />
                <Route path="upload" element={<UploadFood />} />
                <Route path="all-food" element={<AllFood />} />
                {/* <Route path="all-order" element={<AllOrders />} /> */}
                <Route path="order-food" element={<Dashboard />} />
                <Route path="edit-food/:id" element={<EditFood />} />
            </Route>

            <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
    );
}

export default Admin;