import LoaderGif from "../assets/loader.gif";

function Loader() {
  return (
    <div style={style_container}>
      <img src={LoaderGif} style={{ width: "100px", height: "100px" }} />
    </div>
  );
}

export default Loader;

const style_container = {
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: "0px",
  left: "0px",
  display: "flex",
  justifyContent: "center",
  zIndex:"10",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.53)",
};
